import { publicItems } from './history';

const currentLocation = location.pathname;

if (
  !localStorage.getItem('tokens') &&
  ![...publicItems, '/terms-and-conditions'].includes(currentLocation)
) {
  const session_idIndex = location.href.indexOf('session_id');
  if (session_idIndex === -1) {
    location.href = `/login?ref=${btoa(currentLocation)}`;
  }
}
