import { createBrowserHistory } from 'history';

export const history = createBrowserHistory();

export const LANDING_ORG_ROUTE = 'landing-organization';
export const FARM_ROUTE = 'farm';

export const URL_CONSTANTS = {
  ROOT: () => '/',
  LOGIN: () => '/login',
  SIGNUP_1: () => '/sign-up-1',
  SIGNUP_2: () => '/sign-up-2',
  RECOVERY_PASSWORD: () => '/recovery-password',
  RESET_PASSWORD: () => '/reset-password',
  HALL_OF_ORG: () => '/hall-of-org',
  TERMS_AND_CONDITIONS: () => '/terms-and-conditions',

  LANDING_ORGANIZATION: ({ orgId }) => `/${LANDING_ORG_ROUTE}/${orgId}`,
  EDIT_FARM: ({ orgId, farmId }) => `/${LANDING_ORG_ROUTE}/${orgId}/edit-farm/${farmId}`,
  LANDING_FARM: ({ orgId, farmId }) => `/${LANDING_ORG_ROUTE}/${orgId}/${FARM_ROUTE}/${farmId}`,

  ALL_FIELDS: ({ orgId, farmId }) =>
    `/${LANDING_ORG_ROUTE}/${orgId}/${FARM_ROUTE}/${farmId}/all-fields`,
  CREATE_FIELDS: ({ orgId, farmId }) =>
    `/${LANDING_ORG_ROUTE}/${orgId}/${FARM_ROUTE}/${farmId}/create-fields`,
  CREATE_FIELD_SHAPE_FILE_UPLOADER: ({ orgId, farmId }) =>
    `/${LANDING_ORG_ROUTE}/${orgId}/${FARM_ROUTE}/${farmId}/create-field/uploader`,
  CREATE_FIELD_SHAPE_FILE_LIST: ({ orgId, farmId }) =>
    `/${LANDING_ORG_ROUTE}/${orgId}/${FARM_ROUTE}/${farmId}/create-field/uploaded-list`,
  CREATE_FIELD_DRAW: ({ orgId, farmId }) =>
    `/${LANDING_ORG_ROUTE}/${orgId}/${FARM_ROUTE}/${farmId}/create-field/draw-field`,
  EDIT_FIELD_DRAW: ({ orgId, farmId, fieldId }) =>
    `/${LANDING_ORG_ROUTE}/${orgId}/${FARM_ROUTE}/${farmId}/edit-field/${fieldId}`,
  EDIT_FIELD_DRAW_UPLOADED: ({ orgId, farmId }) =>
    `/${LANDING_ORG_ROUTE}/${orgId}/${FARM_ROUTE}/${farmId}/edit-field/upload`,

  DETECT_FIELDS: ({ orgId, farmId }) =>
    `/${LANDING_ORG_ROUTE}/${orgId}/${FARM_ROUTE}/${farmId}/create-field/detect-fields`
};

export const publicItems = [
  '/',
  '/login',
  '/sign-up-1',
  '/sign-up-2',
  '/reset-password',
  '/recovery-password'
];
